<template>
  <div class="Modal" :class="{hide:!show}" @click="backClick" :style="mStyle">
    <div class="box">
      <div class="wrapper">
        <div class="dialog" :class="boxClass" @click="(event)=>{event.stopPropagation()}">
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    props: {
      boxClass: {},
      mStyle: {},
      backdrop: {}
    },
    data() {
      return {
        show: false
      }
    },
    computed: {

    },
    watch: {
      show: function (value, oldvalue) {
        if (oldvalue == false && value == true) {
          if (this.onShow) {
            this.onShow()
          }
          this.$emit('Show')
        }
        if (oldvalue == true && value == false) {
          if (this.onHide) {
            this.onHide()
          }
          this.$emit('Hide')
        }
      }
    },
    mounted() {
      this.$emit('ready', {
        vm: this,
        funcs: {
          show: this.showModal,
          hide: this.hideModal,
        }
      })
    },
    created() {
    },
    destroyed() {
    },
    methods: {
      backClick() {
        if (this.backdrop == 'static' || this.backdrop === false) {
          return
        }
        this.hideModal()
      },
      showModal() {
        this.show = true
      },
      hideModal() {
        this.show = false
      },
      tarageModal() {
        this.show = !this.show
      }
    },
  }
</script>
<style scoped>
  .Modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #0009;
    display: flex;
    justify-content: center;
    align-items: center;
  }

    .Modal.hide {
      display: none
    }

    .Modal .box {
      width: 100%;
      height: 100%;
      overflow: auto;
    }

    .Modal .wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 100%;
      min-width: 100%;
      padding: 10px;
    }

    .Modal .dialog {
      padding: 10px;
      background-color: #fff;
      border: 1px solid #000;
      border-radius: 5px;
      overflow: hidden;
    }
</style>
